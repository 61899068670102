const PROCESSING = 'processing'
const COMPLETED = 'completed'
const ERROR = 'error'
const CANCELLED = 'cancelled'

const IMPORT_STATUS_VALUES = [PROCESSING, COMPLETED, ERROR, CANCELLED]

const DOMA_EXCEL = 'excel_doma'
const CSV = 'csv_1s'
const IMPORT_FORMAT_VALUES = [DOMA_EXCEL, CSV]

const DEFAULT_RECORDS_LIMIT_FOR_IMPORT = 500
const EXTENDED_RECORDS_LIMIT_FOR_IMPORT = 10000

const METER_READINGS_IMPORT_TASK_FOLDER_NAME = 'MeterReadingsImportTask'

module.exports = {
    DEFAULT_RECORDS_LIMIT_FOR_IMPORT,
    EXTENDED_RECORDS_LIMIT_FOR_IMPORT,

    PROCESSING, COMPLETED, ERROR, CANCELLED,
    IMPORT_STATUS_VALUES,

    DOMA_EXCEL, CSV,
    IMPORT_FORMAT_VALUES,

    METER_READINGS_IMPORT_TASK_FOLDER_NAME,
}